import React from "react";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Title,
  Description,
  HorizontalDivider,
  RowWrapper,
  RowItem,
  Grid,
  StageWrapper,
  StageNumber,
  StageDescription,
  DescriptionItem,
  DescriptionText,
  Recruitment1StageIcon,
  Recruitment2StageIcon,
  Recruitment3StageIcon,
  DownArrow,
  RightArrow,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import Breakpoint from "../../../../../common/Breakpoint";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

interface RecruitmentProps {
  pageChange: PageChange;
}

const Recruitment: React.FC<RecruitmentProps> = ({ pageChange }) => {
  const { t } = useTranslation("career");

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`recruitment_title`))} />
      <PageWrapper animation={pageChange}>
        <div>
          <Title>{fixConjunctions(t(`recruitment_title`))}</Title>
          <Description>
            {fixConjunctions(t(`recruitment_description`))}
          </Description>
          <Breakpoint device="computer">
            <HorizontalDivider />
          </Breakpoint>
        </div>

        <Breakpoint device={["tablet", "tabletLandscape"]}>
          <Grid>
            <StageWrapper>
              <StageNumber>01</StageNumber>
              <StageDescription
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`recruitment_firstStep`)),
                }}
              />
            </StageWrapper>
            <Recruitment1StageIcon />
            <DownArrow />
            <StageWrapper>
              <StageNumber>02</StageNumber>
              <StageDescription>
                {((t(`recruitment_secondStep`) as unknown) as string[]).map(
                  e => (
                    <DescriptionItem>
                      -<DescriptionText>{e}</DescriptionText>
                    </DescriptionItem>
                  )
                )}
              </StageDescription>
            </StageWrapper>
            <Recruitment2StageIcon />
            <DownArrow />
            <StageWrapper>
              <StageNumber>03</StageNumber>
              <StageDescription
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`recruitment_thirdStep`)),
                }}
              />
            </StageWrapper>
            <Recruitment3StageIcon />
          </Grid>
        </Breakpoint>

        <Breakpoint device="computer">
          <RowWrapper>
            <RowItem>
              <Recruitment1StageIcon />
              <StageWrapper>
                <StageNumber>01</StageNumber>
                <StageDescription
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(t(`recruitment_firstStep`)),
                  }}
                />
              </StageWrapper>
            </RowItem>
            <RowItem>
              <RightArrow />
            </RowItem>
            <RowItem>
              <Recruitment2StageIcon />
              <StageWrapper>
                <StageNumber>02</StageNumber>
                <StageDescription>
                  {((t(`recruitment_secondStep`) as unknown) as string[]).map(
                    e => (
                      <DescriptionItem>
                        <DescriptionText>{e}</DescriptionText>
                      </DescriptionItem>
                    )
                  )}
                </StageDescription>
              </StageWrapper>
            </RowItem>
            <RowItem>
              <RightArrow />
            </RowItem>
            <RowItem>
              <Recruitment3StageIcon />
              <StageWrapper>
                <StageNumber>03</StageNumber>
                <StageDescription
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(t(`recruitment_thirdStep`)),
                  }}
                />
              </StageWrapper>
            </RowItem>
          </RowWrapper>
        </Breakpoint>
      </PageWrapper>
    </>
  );
};

export default Recruitment;
